import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Drawer, AppBar, Toolbar, List, Typography, Divider, IconButton, ListItem, ListItemButton, ListItemIcon, ListItemText, CircularProgress, Alert, Button, } from '@mui/material';
import { Menu as MenuIcon, Dashboard as DashboardIcon, ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Outlet, useNavigate, useLocation, Link as RouterLink, } from 'react-router-dom';
import { useAuthInfo } from '@propelauth/react';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const MuiAppBar = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: `${drawerWidth}px`,
            transition: theme.transitions.create(['margin', 'width'], {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const navigationItems = [
    { text: 'Team Overview', icon: <DashboardIcon />, path: '/admin' },
];

function AdminDashboard () {
    const [open, setOpen] = useState(true);
    const [organizationId, setOrganizationId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { orgHelper } = useAuthInfo();

    const initializeOrganization = useCallback(async () => {
        try {
            const orgs = orgHelper.getOrgs();

            if (!orgs || orgs.length === 0) {
                throw new Error('No organizations found for the user.');
            }

            const primaryOrg = orgs[0];
            setOrganizationId(primaryOrg.orgId);
            setIsLoading(false);
        } catch (err) {
            console.error('Error initializing organization:', err);
            setError('Unable to load organization information. Please refresh and try again.');
            setIsLoading(false);
        }
    }, [orgHelper]);

    useEffect(() => {
        initializeOrganization();
    }, [initializeOrganization]);

const handleDrawerToggle = () => {
    setOpen(!open);
};

const handleNavigation = (path) => {
    navigate(path);
};

const getCurrentPageTitle = () => {
    const currentItem = navigationItems.find(item => item.path === location.pathname);
    return currentItem ? currentItem.text : 'Dashboard';
};

if (isLoading) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
        >
            <CircularProgress />
            <Typography variant="body2" sx={{ mt: 2 }}>
                Loading Company Data...
            </Typography>
        </Box>
    );
}

if (error) {
    return (
        <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
            p={3}
        >
            <Alert 
                severity="error"
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setIsLoading(true);
                            setError(null);
                            initializeOrganization();
                        }}
                    >
                        Retry
                    </Button>
                }
            >
                {error}
            </Alert>
        </Box>
    );
}

return (
    <Box sx={{ display: 'flex' }}>
        <MuiAppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="toggle drawer"
                    onClick={handleDrawerToggle}
                    edge="start"
                    sx={{ mr: 2 }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                    {getCurrentPageTitle()}
                </Typography>
                <Button
                    color="inherit"
                    component={RouterLink}
                    to="/"
                    startIcon={<ArrowBackIcon />}
                >
                    Back to Simulation Dashboard
                </Button>
            </Toolbar>
        </MuiAppBar>

        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },
            }}
            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader>
                <Typography variant="h6" sx={{ flexGrow: 1, ml: 2 }}>
                    {orgHelper.getOrgs()[0]?.orgName || 'My Organization'}
                </Typography>
            </DrawerHeader>
            <Divider />
            <List>
                {navigationItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton
                            onClick={() => handleNavigation(item.path)}
                            selected={location.pathname === item.path}
                        >
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Drawer>

        <Main open={open}>
            <DrawerHeader />
            <Outlet context={{ organizationId }} />
        </Main>
    </Box>
);
}

export default AdminDashboard;