import React, { useState, useEffect } from 'react';
import { AppBar, Button, Toolbar, IconButton, Avatar, Menu, MenuItem, Box, Typography } from '@mui/material';
import { Settings, Help, Logout } from '@mui/icons-material';
import { useLogoutFunction, useHostedPageUrls, useAuthInfo } from "@propelauth/react";
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import HelpModal from './HelpModal';
import CoachableLogo from '../../assets/CoachableIcon1.png';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import analytics from '../../segmentAnalytics'

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function Header({ dashboardData, userData, onOpenUpgradeModal }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [helpModalOpen, setHelpModalOpen] = useState(false);
    const logoutFunction = useLogoutFunction();
    const { getAccountPageUrl } = useHostedPageUrls();
    const { orgHelper, accessHelper } = useAuthInfo();
    const [isAdmin, setIsAdmin] = useState(false);
    const authInfo = useAuthInfo();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (orgHelper) {
            const orgs = orgHelper.getOrgs();
            if (orgs.length > 0) {
                const firstOrg = orgs[0];
                const isAdminOrOwner = accessHelper.isAtLeastRole(firstOrg.orgId, "Admin");
                setIsAdmin(isAdminOrOwner);
            }
        }
    }, [orgHelper, accessHelper]);

    const isDashboardPage = () => {
        return location.pathname === '/' || location.pathname === '/dashboard';
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await logoutFunction();
    };
    const handleOpenAccountPage = () => {
        window.location.href = getAccountPageUrl()
    };

    const handleSimulationHistoryClick = () => {
        navigate('/simulation-history', { state: { simulations: dashboardData?.completedSimulations } });
    }

    const handleOpenHelpModal = () => {
        setHelpModalOpen(true);
        handleMenuClose();
    };

    const handleUpgrade = () => {
        analytics.track('Upgrade Options Viewed');
        onOpenUpgradeModal();
    };

    const showCredits = userData && (userData.plan === 'Trial' || userData.plan === 'Usage' || userData.plan === 'Unlimited');

    const getUpgradeButtonText = () => {
        if (userData && userData.plan === 'Usage') {
            return 'Purchase Credits';
        }
        return 'Upgrade';
    };

    const handleOpenCustomerPortal = async () => {
        try {
            const response = await fetch(`${apiBaseUrl}/api/stripe/create-customer-portal-session`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify({ userId: authInfo.user.userId }),
            });

            if (!response.ok) {
                throw new Error('Failed to create customer portal session');
            }

            const { url } = await response.json();
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error creating customer portal session:', error);
        }
    };

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <Box component={RouterLink} to="/" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <img src={CoachableLogo} alt="Coachable AI Logo" style={{ height: '40px', marginRight: '10px' }} />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                        <Button color="inherit" onClick={handleSimulationHistoryClick}>
                            Simulation History
                        </Button>
                        {isAdmin && (
                            <Button
                                color="inherit"
                                component={RouterLink}
                                to="/admin"
                                sx={{ ml: 2}}
                            >
                                Team Dashboard
                            </Button>
                        )}
                    </Box>
                    {isDashboardPage() && showCredits && (
                        <Box sx={{ mr: 2 }}>
                            <Typography variant="body2" color="inherit">
                                Simulation Credits Remaining: {userData.plan === 'Unlimited' ? 'Unlimited' : userData.credits}
                            </Typography>
                        </Box>
                    )}
                    {isDashboardPage() && userData && userData.plan !== 'Unlimited' && (
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={handleUpgrade}
                            sx={{ mr: 2 }}
                        >
                            {getUpgradeButtonText()}
                        </Button>
                    )}
                    <IconButton color="inherit" aria-label="settings" onClick={handleMenuOpen}>
                        <Avatar alt={authInfo.user.firstName}>
                            {authInfo.user.firstName ? authInfo.user.firstName[0].toUpperCase() : 'U'}
                        </Avatar>
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={handleOpenAccountPage}>
                            <Settings /> Settings
                        </MenuItem>
                        <MenuItem onClick={handleOpenHelpModal}>
                            <Help /> Help
                        </MenuItem>
                        {userData && (userData.plan === 'Usage' || userData.plan === 'Unlimited') && (
                            <MenuItem onClick={handleOpenCustomerPortal}>
                                <CreditCardIcon /> Manage Subscription
                            </MenuItem>
                        )}
                        <MenuItem onClick={handleLogout}>
                            <Logout /> Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <HelpModal open={helpModalOpen} onClose={() => setHelpModalOpen(false)} />
        </>
    );
}

export default Header;