import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useOutletContext } from 'react-router-dom';
import { Box, Card, CardContent, Grid2 as Grid, Typography, Avatar, Chip, Select, MenuItem, FormControl, InputLabel, CircularProgress, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, IconButton, } from '@mui/material';
import { PlayArrow as PlayArrowIcon, Assessment as AssessmentIcon, Person as PersonIcon, CalendarToday as CalendarTodayIcon, ArrowBack as ArrowBackIcon, Speed as SpeedIcon, } from '@mui/icons-material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const MetricCard = ({ title, value, icon: Icon, suffix= '' }) => (
    <Card>
        <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Icon color="primary" sx={{ mr: 1 }} />
                <Typography variant="h6" color="text.secondary">
                    {title}
                </Typography>
            </Box>
            <Typography variant="h4" component="div">
                {value}{suffix}
            </Typography>
        </CardContent>
    </Card>
);

function UserDetail () {
    const { userId } = useParams();
    const navigate = useNavigate();
    const { organizationId } = useOutletContext();
    const [dateRange, setDateRange] = useState('thisMonth');
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/api/team-dashboard/user?userId=${userId}&dateRange=${dateRange}`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': 'true',
                        }
                    }
                );
    
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
    
                const data = await response.json();
                setUserData(data);
            } catch (err) {
                console.error('Error fetching user data:', err);
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (userId && organizationId) {
            fetchUserData();
        }
    }, [userId, organizationId, dateRange]);
        

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    const handleViewScorecard = (simulationId) => {
        navigate(`/call-review/${simulationId}`);
    };

    const handleBackToOverview = () => {
        navigate('/admin');
    };

    if (isLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="400px">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Alert
                severity="error"
                sx={{ mt: 2 }}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setIsLoading(true);
                            setError(null);
                        }}
                    >
                        Retry
                    </Button>
                }
            >
                {error}
            </Alert>
        );
    }

    if (!userData) return null;

    return (
        <Box>
            <Box sx={{ mb: 4 }}>
                <Button
                    startIcon={<ArrowBackIcon />}
                    onClick={handleBackToOverview}
                    sx={{ mb: 3 }}
                    variant="outlined"
                >
                    Back to Team Overview
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                            sx={{ width: 64, height: 64, mr: 2 }}
                        >
                            {userData.personalInfo.name[0]}
                        </Avatar>
                        <Box>
                            <Typography variant="h4">{userData.personalInfo.name}</Typography>
                            <Typography variant="subtitle1" color="text.secondary">{userData.personalInfo.role}</Typography>
                            <Typography variant="body2" color="text.secondary">Joined {new Date(userData.personalInfo.joinDate).toLocaleDateString()}</Typography>
                        </Box>
                    </Box>
                    <FormControl sx={{ minWidth: 200 }}>
                        <InputLabel>Date Range</InputLabel>
                        <Select
                            value={dateRange}
                            label="Date Range"
                            onChange={handleDateRangeChange}
                        >
                            <MenuItem value="thisMonth">This Month</MenuItem>
                            <MenuItem value="lastMonth">Last Month</MenuItem>
                            <MenuItem value="last7Days">Last 7 Days</MenuItem>
                            <MenuItem value="last30Days">Last 30 Days</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
            </Box>

            <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid xs={12} sm={6} md={3}>
                    <MetricCard
                        title="Total Simulations"
                        value={userData.metrics.totalSimulations}
                        icon={AssessmentIcon}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <MetricCard
                        title="Average Score"
                        value={userData.metrics.averageScore}
                        icon={PersonIcon}
                        suffix="%"
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <MetricCard
                        title="Last Simulation"
                        value={userData.metrics.lastSimulationDate ? new Date(userData.metrics.lastSimulationDate).toLocaleDateString() : 'N/A'}
                        icon={CalendarTodayIcon}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <MetricCard
                        title="Improvement Trend"
                        value={userData.performanceTrend.length > 1 
                            ? `${((userData.performanceTrend[userData.performanceTrend.length - 1].score - userData.performanceTrend[0].score)).toFixed(1)}%`
                            : 'N/A'
                        }
                        icon={SpeedIcon}
                    />
                </Grid>
            </Grid>

            <Card sx={{ mb: 4 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Performance Trend
                    </Typography>
                    <Box sx={{ width: '100%', height: 300 }}>
                        <ResponsiveContainer>
                            <LineChart
                                data={userData.performanceTrend}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="date" tickFormatter={(date) => new Date(date).toLocaleDateString()} />
                                <YAxis />
                                <Tooltip contentStyle={{ backgroundColor: 'white', border: '1px solid #ccc'}} labelStyle={{ color: 'black' }} labelFormatter={(date) => new Date(date).toLocaleDateString()} formatter={(value) => [`${value}%`, 'Score']} />
                                <Line type="monotone" dataKey="score" stroke="#2853eb" strokeWidth={2} dot={{ r: 4 }} activeDot={{ r: 6 }} />
                            </LineChart>
                        </ResponsiveContainer>
                    </Box>
                </CardContent>
            </Card>

            <Card>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Recent Simulations
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell align="right">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userData.recentSimulations.length === 0 ? (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center" sx={{ py: 4 }}>
                                            <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
                                                No simulations completed in this time period
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Try selecting a different date range
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                ) : (
                                    userData.recentSimulations.map((simulation) => (
                                        <TableRow key={simulation.simulationId} hover>
                                            <TableCell>{new Date(simulation.date).toLocaleDateString()}</TableCell>
                                            <TableCell>{simulation.type}</TableCell>
                                            <TableCell>
                                                <Chip
                                                    label={`${simulation.score}%`}
                                                    color={simulation.score >= 80 ? 'success' : simulation.score >= 60 ? 'warning' : 'error'}
                                                    size="small"
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                {simulation.recordingUrl && (
                                                    <IconButton
                                                        href={simulation.recordingUrl}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        size="small"
                                                        sx={{ mr: 1 }}
                                                    >
                                                        <PlayArrowIcon />
                                                    </IconButton>
                                                )}
                                                <Button
                                                    size="small"
                                                    variant="outlined"
                                                    onClick={() => handleViewScorecard(simulation.simulationId)}
                                                >
                                                    View Scorecard
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        </Box>
    );
}

export default UserDetail;