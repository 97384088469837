import React, { useState, useEffect } from 'react';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Select, MenuItem, FormControl, InputLabel, CircularProgress, Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Collapse, IconButton, TextField, Chip } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { TrendingUp as TrendingUpIcon, TrendingDown as TrendingDownIcon, Search as SearchIcon, KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Group as GroupIcon, Assessment as AssessmentIcon, CheckCircle as CheckCircleIcon, } from '@mui/icons-material';

const MetricCard = ({ title, value, icon, trend, suffix = '', isLoading }) => {
    const Icon = icon;
    const showTrend = trend !== undefined && trend !== null;

    return (
        <Card>
            <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Icon color="primary" sx={{ mr: 1 }} />
                    <Typography variant="h6" color="text.secondary">
                        {title}
                    </Typography>
                </Box>
                {isLoading ? (
                    <CircularProgress size={20} />
                ) : (
                    <>
                        <Typography variant="h4" component="div">
                            {value}{suffix}
                        </Typography>
                        {showTrend && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                {trend >= 0 ? (
                                    <TrendingUpIcon color="success" />
                                ) : (
                                    <TrendingDownIcon color="error" />
                                )}
                                <Typography
                                    variant="body2"
                                    color={trend >= 0 ? 'success.main' : 'error.main'}
                                    sx={{ ml: 0.5 }}
                                >
                                    {Math.abs(trend)}% from last period
                                </Typography>
                            </Box>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
};

const TeamMemberRow = ({ member, onUserClick }) => {
    const [open, setOpen] = useState(false);
    const hasSimulations = member.metrics.hasCompletedSimulations;
    
    const getScoreColor = (score) => {
        if (score >= 80) return 'success';
        if (score >= 60) return 'warning';
        return 'error';
    };

    return (
        <>
            <TableRow 
                sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
                hover
            >
                <TableCell>
                    {hasSimulations && (
                        <IconButton
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOpen(!open);
                            }}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell onClick={() => onUserClick(member.userId)}>{member.name}</TableCell>
                <TableCell onClick={() => onUserClick(member.userId)}>{member.role}</TableCell>
                <TableCell onClick={() => onUserClick(member.userId)}>
                    <Chip
                        label={`${member.metrics.averageScore}%`}
                        color={getScoreColor(member.metrics.averageScore)}
                        size="small"
                    />
                </TableCell>
                <TableCell onClick={() => onUserClick(member.userId)}>{member.metrics.completedSimulations}</TableCell>
                <TableCell onClick={() => onUserClick(member.userId)}>
                    {member.metrics.lastSimulationDate 
                        ? new Date(member.metrics.lastSimulationDate).toLocaleDateString()
                        : 'No simulations'}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Typography variant="subtitle1" gutterBottom component="div">
                                Performance Details
                            </Typography>
                            <Box sx={{ 
                                display: 'flex', 
                                gap: 4,
                                mb: 2 
                            }}>
                                <Box>
                                    <Typography 
                                        component="span" 
                                        variant="body2" 
                                        fontWeight="bold"
                                        color="text.primary"
                                    >
                                        Top Skill:{' '}
                                    </Typography>
                                    <Typography 
                                        component="span" 
                                        variant="body2" 
                                        color="text.secondary"
                                    >
                                        {member.metrics.topSkill}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography 
                                        component="span" 
                                        variant="body2" 
                                        fontWeight="bold"
                                        color="text.primary"
                                    >
                                        Area for Improvement:{' '}
                                    </Typography>
                                    <Typography 
                                        component="span" 
                                        variant="body2" 
                                        color="text.secondary"
                                    >
                                        {member.metrics.improvementArea}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

function TeamOverview () {
    const { organizationId } = useOutletContext();
    const navigate = useNavigate();
    const [dateRange, setDateRange] = useState('thisMonth');
    const [metrics, setMetrics] = useState(null);
    const [teamData, setTeamData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [orderBy, setOrderBy] = useState('name');
    const [order, setOrder] = useState('asc');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/api/team-dashboard/overview?orgId=${organizationId}&dateRange=${dateRange}`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': 'true',
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch team metrics');
                }

                const data = await response.json();
                setMetrics(data.metrics);
            } catch (err) {
                console.error('Error fetching team metrics:', err);
                setError(err.message);
            } finally {
                setIsLoading(false);
            }
        };

        if (organizationId) {
            fetchData();
        }
    }, [organizationId, dateRange]);

    const handleDateRangeChange = (event) => {
        setDateRange(event.target.value);
    };

    useEffect(() => {
        const fetchTeamData = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_API_BASE_URL}/api/team-dashboard/individual-performance?orgId=${organizationId}&dateRange=${dateRange}`,
                    {
                        headers: {
                            'ngrok-skip-browser-warning': 'true',
                        }
                    }
                );

                if (!response.ok) {
                    throw new Error('Failed to fetch team performance data');
                }

                const data = await response.json();
                setTeamData(data);
            } catch (err) {
                console.error('Error fetching team performance data:', err);
                setError(err.message);
            }
        };

        if (organizationId) {
            fetchTeamData();
        }
    }, [organizationId, dateRange]);

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleUserClick = (userId) => {
        navigate(`/admin/user/${userId}`);
    };

    const filteredAndSortedTeamMembers = teamData?.teamMembers
        ?.filter(member => 
            member.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            member.role.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            const compareValue = (va, vb) => (va < vb ? -1 : va > vb ? 1 : 0);
            let comparison;

            switch (orderBy) {
                case 'name':
                    comparison = compareValue(a.name, b.name);
                    break;
                case 'role':
                    comparison = compareValue(a.role, b.role);
                    break;
                case 'score':
                    comparison = compareValue(a.metrics.averageScore, b.metrics.averageScore);
                    break;
                case 'simulations':
                    comparison = compareValue(a.metrics.completedSimulations, b.metrics.completedSimulations);
                    break;
                case 'lastSimulation':
                    comparison = compareValue(
                        a.metrics.lastSimulationDate || '0',
                        b.metrics.lastSimulationDate || '0'
                    );
                    break;
                default:
                    comparison = 0;
            }

            return order === 'desc' ? -comparison : comparison;
        });

        if (error) {
            return (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            );
        }

    return (
        <Box>
            <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h4" gutterBottom>
                    Team Performance
                </Typography>
                <FormControl sx={{ minWidth: 200 }}>
                    <InputLabel>Date Range</InputLabel>
                    <Select
                        value={dateRange}
                        label="Date Range"
                        onChange={handleDateRangeChange}
                    >
                        <MenuItem value="today">Today</MenuItem>
                        <MenuItem value="yesterday">Yesterday</MenuItem>
                        <MenuItem value="thisWeek">This Week</MenuItem>
                        <MenuItem value="lastWeek">Last Week</MenuItem>
                        <MenuItem value="thisMonth">This Month</MenuItem>
                        <MenuItem value="lastMonth">Last Month</MenuItem>
                        <MenuItem value="last7Days">Last 7 Days</MenuItem>
                        <MenuItem value="last30Days">Last 30 Days</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={3}>
                    <MetricCard
                        title="Active Users"
                        value={metrics?.activeUsers || 0}
                        suffix={` / ${metrics?.totalTeamMembers || 0}`}
                        icon={GroupIcon}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <MetricCard
                        title="Average Team Score"
                        value={metrics?.averageTeamScore || 0}
                        suffix="%"
                        icon={AssessmentIcon}
                        trend={metrics?.improvementFromLastPeriod}
                        isLoading={isLoading}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={3}>
                    <MetricCard
                        title="Completed Simulations"
                        value={metrics?.completedSimulations || 0}
                        icon={CheckCircleIcon}
                        isLoading={isLoading}
                    />
                </Grid>
            </Grid>
            <Box sx={{ mt: 4, mb: 2 }}>
                <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search team members..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                        startAdornment: <SearchIcon sx={{ mr: 1, color: 'text.secondary' }} />,
                    }}
                />
            </Box>

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell width={50} />
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'name'}
                                    direction={orderBy === 'name' ? order : 'asc'}
                                    onClick={() => handleSort('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'role'}
                                    direction={orderBy === 'role' ? order : 'asc'}
                                    onClick={() => handleSort('role')}
                                >
                                    Role
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'score'}
                                    direction={orderBy === 'score' ? order : 'asc'}
                                    onClick={() => handleSort('score')}
                                >
                                    Avg Score
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'simulations'}
                                    direction={orderBy === 'simulations' ? order : 'asc'}
                                    onClick={() => handleSort('simulations')}
                                >
                                    Simulations
                                </TableSortLabel>
                            </TableCell>
                            <TableCell>
                                <TableSortLabel
                                    active={orderBy === 'lastSimulation'}
                                    direction={orderBy === 'lastSimulation' ? order : 'asc'}
                                    onClick={() => handleSort('lastSimulation')}
                                >
                                    Last Simulation
                                </TableSortLabel>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedTeamMembers?.map((member) => (
                            <TeamMemberRow
                                key={member.userId}
                                member={member}
                                onUserClick={handleUserClick}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default TeamOverview;